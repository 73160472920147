import { formatMoney } from "./helpers";
import { $ } from "./bling";

function populateCart() {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const cartTag = $("#cart");
  const cartHeader = `
    <thead id="cart-table-header">
      <td></td>
      <td></td>
      <td></td>
      <td>Cena jedn.</td>
      <td>Ilość</td>
      <td></td>
      <td>Wartość</td>
      <td></td>
    </thead>
  `
  const cartElements = cart
    .map((item, index) => {
      return `
        <tr>
          <td>
            <img src="/uploads/${item.image}" alt="${item.name}" width=60>
          </td>
          <td>
            <h3>${item.name}</h3>
          </td>
          <td>
            <div class="cart-colors">
              <p>${item.color || ""}</p>
              ${displayPickedSetItems(item.pickedSetItems)}
            </div>
          </td>
          <td>
            <p>${formatMoney(item.price)}</p>
          </td>
          <td>
            <input type="text" value=${item.quantity} id=item-quantity class="item-quantity" >
          </td>
          <td>
            <button class="item-quantity-button" >${'&circlearrowleft;'}</button>
          </td>
          <td>
            <p class="total-item">${formatMoney(item.price * item.quantity)}</p>
          </td>
          <td>
            <button class="remove-from-cart">${`&times;`}</button>  
            <div class="hidden cart-item-index">${index}</div>
          </td>
        </tr>
      `;
    })
    .join("");
  cartTag.innerHTML = "<table class='cart-table'>" + cartHeader + cartElements + "</table>";
}

function displayPickedSetItems(setItems) {
  return setItems
    .map((setItem) => {
      return `
        <p class="setItem-name">${setItem.name}
          <span class="setItem-color">${setItem.color}</span>
        </p>
    `;
    })
    .join("");
}

module.exports = { populateCart };
