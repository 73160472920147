import axios from "axios";
import { $ } from "./bling";
import { formatMoney } from "./helpers";

function checkCouponAndCountTotalPrice(e) {
  const couponLS = JSON.parse(localStorage.getItem('coupon'));
  if ((e && e.type === "click") || (e && e.key === "Enter") || (couponLS && couponLS.coupon)) {
    const coupon = this && this.parentElement.querySelector("#coupon-input").value || couponLS.coupon;
    const cartValue =
      Number($("#cart-items-price-unformatted").textContent) || 0;
    axios
      .get(`/coupons?coupon=${coupon},${cartValue}`)
      .then((res) => {
        const couponInfo = $("#coupon-info");
        couponInfo.innerHTML = res.data.message;
        const discountTag = $("#discount-value ");
        const discountUnformattedTag = $("#discount-value-unformatted");
        discountTag.innerHTML = "-" + formatMoney(0);
        discountUnformattedTag.innerHTML = 0;
        localStorage.removeItem("coupon");

        if (res.data.success) {
          discountTag.innerHTML = "-" + formatMoney(res.data.discount);
          discountUnformattedTag.innerHTML = res.data.discount;
          const couponTableRow = $('#coupon-tr');
          couponTableRow.classList.remove('hidden');

          const afterDiscountPriceTag = $('#after-discount-price');
          afterDiscountPriceTag.innerHTML = formatMoney(cartValue - res.data.discount)
          const afterDiscountUnformattedPriceTag = $('#after-discount-unformatted-price');
          afterDiscountUnformattedPriceTag.innerHTML = cartValue - res.data.discount

          const afterDiscountTableRow = $('#after-discount-tr');
          afterDiscountTableRow.classList.remove('hidden');

          const localStorageCoupon = {
            coupon: coupon,
            value: res.data.value,
            unit: res.data.unit,
            discount: res.data.discount
          };
          localStorage.setItem("coupon", JSON.stringify(localStorageCoupon));

          // ** calculate shipment and total price after waiting for coupon **
          const itemsPrice = cartValue - res.data.discount;
          const shipmentPriceTag = $("#cart-shipment-price");
          const shipmentPriceUnformattedTag = $("#cart-shipment-price-unformatted");

          const shipmentPrices = JSON.parse($('#shipment-prices').textContent);
          const minFreeShipmentOrderValue = shipmentPrices.minFreeShipmentOrderValue;
          const cheapestShipment = shipmentPrices.cheapestShipment;

          const freeShipmentCounter = $("#free-shipment-counter");

          const totalPrice = $('#total-price');

          if (itemsPrice >= minFreeShipmentOrderValue) {
            shipmentPriceTag.innerHTML = "GRATIS";
            shipmentPriceUnformattedTag.innerHTML = 0;

            freeShipmentCounter.classList.add('hidden');

            totalPrice.innerHTML = formatMoney(itemsPrice + 0);
          }
          else {
            shipmentPriceTag.innerHTML = "od " + formatMoney(cheapestShipment);
            shipmentPriceUnformattedTag.innerHTML = cheapestShipment;

            freeShipmentCounter.classList.remove('hidden');
            freeShipmentCounter.innerHTML = `Do darmowej dostawy brakuje: ${formatMoney(minFreeShipmentOrderValue - itemsPrice)}`;

            totalPrice.innerHTML = formatMoney(itemsPrice + cheapestShipment)
          }
        }
      })
      .catch((err) => console.error(err));
  }
}

module.exports = { checkCouponAndCountTotalPrice };
