// **GLOBAL IMPORTS**
// this import is necessary to avoid console error
import regeneratorRuntime from "regenerator-runtime";
import "../sass/style.scss";
import { $, $$, on } from "./modules/bling";
import { updateCounter } from "./modules/updateCounter";
// import { populateSmallCart } from "./modules/populateSmallCart";
import { cookieBanner } from "./modules/cookieBanner";

// **SLIDERS IMPORTS**
import {
  runHeroSlider,
  runHeroMobileSlider,
  runCategoriesMobileSlider,
  runSetsSlider,
} from "./modules/sliders";

// **ITEM PAGE IMPORTS**
import {
  singleItemAvailabilityOnPageLoad,
  updateSetAvailabilityOnPageLoad,
  checkSetAvailability,
} from "./modules/item_availability";
import { updatePrice } from "./modules/updatePrice";
import { handleQuantityChange } from "./modules/item_handleQuantityChange";
import { addItemToCart } from "./modules/addItemToCart";
import { addSetToCart } from "./modules/addSetToCart";
import { displayGallery } from "./modules/displayGallery";

// **CART IMPORTS**
import { populateCart } from "./modules/populateCart";
import { calculateCartItemsPrice } from "./modules/calculateCartItemsPrice";
import { calculateShipmentAndTotalPriceIfNoCoupon } from "./modules/calculateShipmentAndTotalPrice";
import {
  changeItemQty,
  removeFromCart,
  ifCartEmptyHideSummary,
} from "./modules/cart";
import { checkCouponAndCountTotalPrice } from "./modules/checkCoupon";

// **ADMIN IMPORTS**
import { getDragAfterElement } from "./modules/dragDrop";
// import { placeOldImagesIntoInput } from "./modules/editItem/placeOldImagesIntoInput";

// ** GLOBALS **
// ** clear local storage **
// window.localStorage.clear();

// ** get cart **
// const cart = JSON.parse(localStorage.getItem("cart")) || [];

// ** shift sticky nav header from document body **
const topNav = $("#top-nav");
if (topNav) shiftNav();

function shiftNav() {
  const yellowStrip = $("#yellow-strip");
  document.body.style.paddingTop =
    topNav.offsetHeight - yellowStrip.offsetHeight + "px";
}

// ** update item number **
const counterTags = $$(".item-counter");
if (counterTags) counterTags.forEach((tag) => updateCounter(tag));

// ** cookie banner **
const cookieBannerTag = document.querySelector(".cookie-banner");
if (cookieBannerTag) {
  cookieBanner();
}

// **   MAIN PAGE **
const mainPageGallery = $("#main-gallery-glider");
// move slider up so that whitespace between slider and top menu dissapears
// if (mainPageGallery) {
//   const sliderHeight = mainPageGallery.getBoundingClientRect().height;
//   const imgHeight = $('.glide__slide').getBoundingClientRect().height;
//   mainPageGallery.style.marginTop = -(sliderHeight - imgHeight) / 2 + "px";
// }

const mainPageGalleryMobile = $("#main-gallery-mobile-glider");
// move MOBILE slider up so that whitespace between slider and top menu dissapears
// if (mainPageGalleryMobile) {
//   const sliderHeight = mainPageGalleryMobile.getBoundingClientRect().height;
//   const imgHeight = $('.glide__slide img').getBoundingClientRect().height;
//   console.log(-(sliderHeight - imgHeight) / 2 + "px");
// mainPageGalleryMobile.style.marginTop = -(sliderHeight - imgHeight) / 2 + "px";
// }

// ** hero slider **
if (mainPageGallery) {
  runHeroSlider();
}
// ** hero mobile slider **
if (mainPageGalleryMobile) {
  runHeroMobileSlider();
}

// ** categories mobile slider **
const categoriesMobileGlider = $("#categories-mobile-glider");
if (categoriesMobileGlider) {
  runCategoriesMobileSlider();
}

// ** sets slider **
const setsSlider = $("#sets-glider");
if (setsSlider) {
  runSetsSlider();
}

// ** display small cart ** - HIDDEN TO SIMPLIFY ROUTING TO /koszyk, possible to implement in later app version
// const cartDiv = $("#cart-small");
// if (cartDiv) {
//   populateSmallCart(cart, cartDiv);
// }

// ** CARD ITEM **
// ** if no second picture, show first one on hover **
const secondImages = $$(".show-on-hover");
if (secondImages.length) {
  secondImages.forEach((img) => {
    if (img.src.includes("undefined")) {
      img.src = img.parentNode.querySelector(".hide-on-hover").src;
    }
  });
}

// ** ITEM PAGE **

// ** SET ITEM PAGE: pre-check item colors **
const setItemColorCategories = $$(".colorSelect");
setItemColorCategories.forEach((category) => {
  category.querySelector("input").checked = true;
  category.querySelector("span").classList.add("current");
});

// ** SINGLE ITEM PAGE: turn off current item link on item page **
const turnOffLink = document.querySelector(".not-active");
if (turnOffLink)
  turnOffLink.addEventListener("click", function (e) {
    e.preventDefault();
  });

// ** SINGLE ITEM PAGE: check if customer has item in cart and update item availability **
const addToCartButton = $(".add-to-cart");
if (addToCartButton) singleItemAvailabilityOnPageLoad();

// ** SET ITEM PAGE: check if customer has item in cart, check set items availability and update hidden item availability object **
if (addToCartButton) updateSetAvailabilityOnPageLoad();

// ** SET ITEM PAGE: onclick 1. marks setItems colors (or slide types) + 2. changes product price and availability **
const setPickersSquares = $$(".product-picker span");
if (setPickersSquares.length) updatePrice();
setPickersSquares.forEach((square) =>
  square.addEventListener("click", function (e) {
    markPickerSquare(e);
    updatePrice();
    checkSetAvailability();
  })
);

function markPickerSquare(e) {
  const setPickerSquares = e.currentTarget.parentNode.parentNode.querySelectorAll(
    "span"
  );
  setPickerSquares.forEach((color) => color.classList.remove("current"));
  e.currentTarget.classList.add("current");
}

// ** SPECIAL: Ścieżka (nie)równowagi - handle quantity
const quantityIncrease = $(".quantity__button--increase");
if (quantityIncrease) {
  handleQuantityChange();
}

// ** add item to cart **
const addToCartForm = $(".add-to-cart");
if (addToCartForm) {
  const item = JSON.parse($("#item-data").textContent);
  addToCartForm.on("click", item.isSet ? addSetToCart : addItemToCart);
}

const gallery = $(".gallery");
if (gallery) displayGallery();

// ** CART PAGE **

// ** if cart is empty hide cart summary **
const cartSummary = $("#cart-summary");
if (cartSummary) ifCartEmptyHideSummary();

// ** display cart items **
const cartTag = $("#cart");
if (cartTag) populateCart();

// ** count price of cart items **
const cartItemsPriceTag = $("#cart-items-price");
if (cartItemsPriceTag) calculateCartItemsPrice();

// ** check and apply coupon + create coupon object in localStorage && display coupon info from localStorage on page load  **
// ** + count shipment and total prices AFTER COUPON **
const couponButton = $("#coupon-button");
if (couponButton) {
  checkCouponAndCountTotalPrice();
  couponButton.addEventListener("click", checkCouponAndCountTotalPrice);
}
const couponInput = $("#coupon-input");
if (couponInput)
  couponInput.addEventListener("keypress", checkCouponAndCountTotalPrice);

// ** if NO COUPON count shipment and total prices
const shipmentPriceTag = $("#cart-shipment-price");
if (shipmentPriceTag) calculateShipmentAndTotalPriceIfNoCoupon();

// ** remove from cart **
const removeFromCartTags = $$(".remove-from-cart");
if (removeFromCartTags)
  removeFromCartTags.forEach((tag) =>
    tag.addEventListener("click", removeFromCart)
  );

// ** change item qty **
const changeItemQuantityTags = $$(".item-quantity-button");
if (changeItemQuantityTags)
  changeItemQuantityTags.forEach((tag) =>
    tag.addEventListener("click", changeItemQty)
  );

const changeItemQuantityInputs = $$(".item-quantity");
if (changeItemQuantityInputs.length) {
  changeItemQuantityInputs.forEach((input) =>
    input.addEventListener("keypress", changeItemQty)
  );
}

// ** ORDER CONFIRMATION **

// ** clear local storage (cart items) after order has been placed AND update counters**
const orderConfirmation = $("#order-confirmation");
if (orderConfirmation) {
  window.localStorage.clear();
  const counterTags = $$(".item-counter");
  if (counterTags) counterTags.forEach((tag) => updateCounter(tag));
}

// ** ADMIN **

// ** EDIT ITEM **

// ** drag n' drop photos in edit item mode & get array of images **
const draggables = $$(".draggable");
const container = $(".uploaded-images-container");

if (draggables) {
  draggables.forEach((draggable) => {
    draggable.on("dragstart", () => {
      draggable.classList.add("dragging");
    });

    draggable.on("dragend", () => {
      draggable.classList.remove("dragging");
      // get images addresses in the correct order (as they were dragged)
      placeOldImagesIntoInput(oldImagesInput);
    });
  });
}

if (container) {
  container.on("dragover", (e) => {
    const afterElement = getDragAfterElement(container, e.clientY);
    const draggable = $(".dragging");
    if (afterElement === null) container.appendChild(draggable);
    else container.insertBefore(draggable, afterElement);
  });
}

// ** preserve old images **
const oldImagesInput = $("#old-images-input");
if (oldImagesInput) placeOldImagesIntoInput(oldImagesInput);

const mobileMenuTogglers = $$(".mobile-menu-toggler");

mobileMenuTogglers.forEach((toggler) => {
  toggler.addEventListener("click", () => {
    const mobileMenu = $(".menu-mobile");
    mobileMenu.classList.toggle("menu-mobile-toggle");
    const mobileMenuInner = $(".menu-mobile-inner");
    mobileMenuInner.classList.toggle("menu-mobile-inner-toggle");
  });
});

function placeOldImagesIntoInput(oldImagesInput) {
  if ($(".uploaded-images-container")) {
    let imageTags = $(".uploaded-images-container").querySelectorAll("img");
    let srcArray = imageTags.map((img) => {
      let path = img.getAttribute("src");
      let slash = path.lastIndexOf("/");
      let src = path.slice(slash + 1);
      return src;
    });
    oldImagesInput.value = srcArray;
  }
}

// ** setItems are selected in edit item mode **
const setItemsTags = $$('select[name="setItems"]');
if (setItemsTags.length) {
  const setItems = JSON.parse($("#set-items").textContent);
  setItems.forEach((id, index) => {
    for (let i = 0; i < setItemsTags[0].options.length; i++) {
      if (setItemsTags[index].options[i].value === id)
        setItemsTags[index].options[i].selected = true;
    }
  });
}

// ** correct slideType is selected in edit item mode **
const slideType = $("#slide-type-data")
  ? $("#slide-type-data").textContent
  : undefined;
if (slideType) {
  const slideOptions = $$(".slide-type-option");
  slideOptions.forEach((option) => {
    if (option.value === slideType) option.selected = true;
  });
}

// ** EDIT ORDER **

// ** order status and notes are displayed in edit order mode **
const statusTag = $('select[name="status"]');
if (statusTag) {
  const orderStatus = $("#order-status").textContent;
  for (let option of statusTag.options) {
    if (orderStatus === option.value) option.selected = true;
  }
}
