import { $ } from "./bling";
import { inflectWordPrzedmiot } from "./helpers";

function updateCounter(counterTag) {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const itemNumber = countItems(cart);
  counterTag.innerHTML = itemNumber;
  styleRoundCounter(itemNumber);
  const text = $(".item-counter-text");
  if (text) text.innerHTML = ` ${inflectWordPrzedmiot(itemNumber)} w koszyku`;
}

function countItems(cart) {
  let itemNumber = 0;
  cart.forEach((item) => (itemNumber += item.quantity));
  return itemNumber;
}

function styleRoundCounter(itemNumber) {
  let roundCounter = $("#round-counter");
  if (itemNumber === 0) roundCounter.style.display = "none";
  else roundCounter.style.display = "block";
}

module.exports = { updateCounter };
