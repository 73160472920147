import { $, $$ } from "./bling";

function displayGallery() {
  const current = $("#current-img");
  const imgs = $(".imgs");
  const img = $$(".imgs img");
  const opacity = 0.6;

  const prev = $('#prev-button');
  const next = $('#next-button');

  img[0].style.opacity = opacity;

  imgs.addEventListener("click", imgClick);

  function imgClick(e) {
    // Reset the opacity
    img.forEach((img) => (img.style.opacity = 1));

    // Change current image to src of clicked image
    current.src = e.target.src;

    // Add fade in class
    current.classList.add("fade-in");

    // Remove fade-in class after .5 seconds
    setTimeout(() => current.classList.remove("fade-in"), 500);

    // Change the opacity to opacity var
    e.target.style.opacity = opacity;
  }

  prev.addEventListener('click', prevClick);

  function prevClick() {
    img.forEach((img) => img.style.opacity = 1)

    for (let i = 0; i < img.length; i++) {
      if (img[i].src === current.src) {
        if (i === 0) i = img.length;
        current.src = img[i - 1].src;
        current.classList.add("fade-in");
        setTimeout(() => current.classList.remove("fade-in"), 500);

        let siteNameEndPosition = current.src.indexOf('/uploads');
        let src = img[i - 1].src.slice(siteNameEndPosition);
        const smallPic = $(`.imgs img[src="${src}"]`);
        smallPic.style.opacity = opacity;
        return;
      }
    }
  }

  next.addEventListener('click', nextClick);

  function nextClick() {
    img.forEach((img) => img.style.opacity = 1)

    for (let i = 0; i < img.length; i++) {
      if (img[i].src === current.src) {
        if (i === img.length - 1) i = -1;
        current.src = img[i + 1].src;
        current.classList.add("fade-in");
        setTimeout(() => current.classList.remove("fade-in"), 500);

        let siteNameEndPosition = current.src.indexOf('/uploads');
        let src = img[i + 1].src.slice(siteNameEndPosition);
        const smallPic = $(`.imgs img[src="${src}"]`);
        smallPic.style.opacity = opacity;
        return;
      }
    }
  }

}

module.exports = { displayGallery };
