import { $, $$ } from "./bling";
import { populateCart } from "./populateCart";
import { calculateCartItemsPrice } from "./calculateCartItemsPrice";
import { calculateShipmentAndTotalPriceIfNoCoupon } from "./calculateShipmentAndTotalPrice";
import { updateCounter } from "./updateCounter";
import { checkCouponAndCountTotalPrice } from "./checkCoupon";

function ifCartEmptyHideSummary() {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const cartSummary = $("#cart-summary");
  if (!cart.length) {
    cartSummary.style.display = "none";
  }
  const cartTable = $('#cart');
  if (!cart.length) {
    cartTable.style.display = "none";
  }
}

function removeFromCart() {
  const index = Number(
    this.parentElement.querySelector(".cart-item-index").textContent
  );
  let cart = JSON.parse(localStorage.getItem("cart")) || [];
  cart.splice(index, 1);
  localStorage.setItem("cart", JSON.stringify(cart));

  populateCart();
  calculateCartItemsPrice();
  checkCouponAndCountTotalPrice();
  calculateShipmentAndTotalPriceIfNoCoupon();
  const counterTags = $$(".item-counter");
  if (counterTags) counterTags.forEach((tag) => updateCounter(tag));
  const removeFromCartTags = $$(".remove-from-cart");
  removeFromCartTags.forEach((tag) =>
    tag.addEventListener("click", removeFromCart)
  );
  const changeItemQuantityTags = $$(".item-quantity-button");
  changeItemQuantityTags.forEach((tag) =>
    tag.addEventListener("click", changeItemQty)
  );
  ifCartEmptyHideSummary();

}

function changeItemQty(e) {
  if (e.type === "click" || e.key === "Enter") {

    let qty = Number(this.parentElement.parentElement.querySelector(".item-quantity").value);
    let index = Number(
      this.parentElement.parentElement.querySelector(".cart-item-index").textContent
    );
    let cart = JSON.parse(localStorage.getItem("cart"));
    cart[index].quantity = qty;
    localStorage.setItem("cart", JSON.stringify(cart));

    populateCart();
    calculateCartItemsPrice();
    checkCouponAndCountTotalPrice();
    calculateShipmentAndTotalPriceIfNoCoupon();
    const counterTags = $$(".item-counter");
    if (counterTags) counterTags.forEach((tag) => updateCounter(tag));
    const changeItemQuantityTags = $$(".item-quantity-button");
    changeItemQuantityTags.forEach((tag) =>
      tag.addEventListener("click", changeItemQty)
    );
    const removeFromCartTags = $$(".remove-from-cart");
    removeFromCartTags.forEach((tag) => {
      tag.addEventListener("click", removeFromCart);
    });
    const changeItemQuantityInputs = $$('.item-quantity');
    if (changeItemQuantityInputs.length) {
      changeItemQuantityInputs.forEach(input => input.addEventListener('keypress', changeItemQty))
    }
  }
}

module.exports = { changeItemQty, removeFromCart, ifCartEmptyHideSummary };
