import { $, $$ } from './bling';
import { formatMoney } from './helpers';

export function handleQuantityChange() {
  countDisplayPrice();

  const quantityInput = $('.quantity__input');
  quantityInput.addEventListener('input', function () {
    countDisplayPrice();
  });

  const quantityIncrease = $('.quantity__button--increase');
  quantityIncrease.addEventListener('click', function () {
    const quantityInput = $('.quantity__input');
    quantityInput.value++;

    countDisplayPrice();
  });

  const quantityDecrease = $('.quantity__button--decrease');
  quantityDecrease.addEventListener('click', function () {
    const quantityInput = $('.quantity__input');
    if (quantityInput.value <= 1) return;
    quantityInput.value--

    countDisplayPrice();
  });
}

function countDisplayPrice() {
  const quantityInput = $('.quantity__input');
  const quantity = quantityInput.value;
  const price = JSON.parse($('#item-data').textContent).price;
  const totalPriceTag = $('.quantity__item-price');
  totalPriceTag.textContent = formatMoney(quantity * price);
};