import { $, $$, on } from "./bling";
import { updateCounter } from "./updateCounter";
import { updateSetAvailabilityOnPageLoad } from "./item_availability";

function addSetToCart() {
  const addButton = $(".big-yellow-button");
  addButton.style.opacity = 0.7;
  setTimeout(() => (addButton.style.opacity = 1), 300);

  const item = JSON.parse($("#item-data").textContent);
  const newPickedSetItems = generatePickedSetItems();
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const wasItemFound = cart.map((oldItem) => {
    if (oldItem._id === item._id) {
      const oldPickedSetItems = oldItem.pickedSetItems;
      const oldPickedSetItemsIds = oldPickedSetItems.map((item) => item._id);
      const newPickedSetItemsIds = newPickedSetItems.map((item) => item._id);
      const oldPickedSetItemsIdsSorted = oldPickedSetItemsIds.slice().sort();
      const areSetsSame =
        newPickedSetItemsIds.length === oldPickedSetItemsIdsSorted.length &&
        newPickedSetItemsIds
          .slice()
          .sort()
          .every(function (value, index) {
            return value === oldPickedSetItemsIdsSorted[index];
          });
      if (areSetsSame) {
        oldItem.quantity++;
        return true;
      } else return false;
    } else return false;
  });
  if (wasItemFound.every((i) => i === false) || cart.length === 0) {
    const newCartItem = {
      _id: item._id,
      quantity: 1,
      price: $("#item-price-unformatted").textContent,
      image: item.images[0],
      name: item.name,
      color: null,
      shipWithInpost: item.shipWithInpost,
      isItemSet: true,
      pickedSetItems: newPickedSetItems,
    };
    cart.push(newCartItem);
  }
  localStorage.setItem("cart", JSON.stringify(cart));

  updateSetAvailabilityOnPageLoad();

  const counterTags = $$(".item-counter");
  if (counterTags) counterTags.forEach((tag) => updateCounter(tag));
}

// * wygeneruj tablicę obiektów - przedmiotów w zestawie *
function generatePickedSetItems() {
  const setItemsTags = $$(".colorSelect");
  let setItems = [];
  for (let i = 0; i < setItemsTags.length; i++) {
    const squares = setItemsTags[i].querySelectorAll("span");
    const inputs = setItemsTags[i].querySelectorAll("input");
    for (let j = 0; j < squares.length; j++) {
      if (squares[j].classList.contains("current")) {
        setItems.push(JSON.parse(inputs[j].value));
      }
    }
  }
  return setItems;
}

module.exports = { addSetToCart };
