exports.formatMoney = (amount) => {
  const options = {
    style: "currency",
    currency: "PLN",
    minimumFractionDigits: 2,
  };
  // if it's a whole, pln amount, leave off the .00
  if (amount % 100 === 0) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat("pl-PL", options);
  return formatter.format(amount / 100);
};

exports.addPrice = 50;

exports.inflectWordPrzedmiot = (number) => {
  if (number === 1) return "przedmiot";
  if (
    (number >= 2 && number <= 4) ||
    (number >= 22 && number <= 24) ||
    (number >= 32 && number <= 34) ||
    (number >= 42 && number <= 44)
  )
    return "przedmioty";
  return "przedmiotów";
};

exports.siteAddress = "https://www.bujolujo.pl/";