import Glide from "@glidejs/glide";

function runHeroSlider() {
  new Glide("#main-gallery-glider", {
    type: "carousel",
    startAt: 0,
    perView: 1,
    focusAt: "center",
    autoplay: 3000,
  }).mount();
}

function runHeroMobileSlider() {
  new Glide("#main-gallery-mobile-glider", {
    type: "carousel",
    startAt: 0,
    perView: 1,
    focusAt: "center",
    autoplay: 3000,
  }).mount();
}

function runCategoriesMobileSlider() {
  new Glide("#categories-mobile-glider", {
    type: "carousel",
    startAt: 0,
    perView: 1,
    focusAt: "center",
    autoplay: 3000,
  }).mount();
}

function runSetsSlider() {
  if (window.innerWidth > 1100) {
    new Glide("#sets-glider", {
      type: "carousel",
      startAt: 0,
      perView: 4,
    }).mount();
  } else if (window.innerWidth > 900) {
    new Glide("#sets-glider", {
      type: "carousel",
      startAt: 0,
      perView: 3.5,
    }).mount();
  } else if (window.innerWidth > 800) {
    new Glide("#sets-glider", {
      type: "carousel",
      startAt: 0,
      perView: 3,
    }).mount();
  } else if (window.innerWidth > 600) {
    new Glide("#sets-glider", {
      type: "carousel",
      startAt: 0,
      perView: 2.5,
    }).mount();
  } else {
    new Glide("#sets-glider", {
      type: "carousel",
      startAt: 0,
      perView: 1,
    }).mount();
  }
}

module.exports = {
  runHeroSlider,
  runCategoriesMobileSlider,
  runSetsSlider,
  runHeroMobileSlider,
};
