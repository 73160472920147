import { formatMoney } from "./helpers";
import { $ } from "./bling";

function calculateCartItemsPrice() {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const cartItemsPriceTag = $("#cart-items-price");
  const cartItemsHiddenPriceTag = $("#cart-items-price-unformatted");
  cartItemsPriceTag.innerHTML = formatMoney(
    cart.reduce((total, item) => total + item.price * item.quantity, 0)
  );
  cartItemsHiddenPriceTag.innerHTML = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
}

module.exports = { calculateCartItemsPrice };
