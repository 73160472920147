import { $ } from "./bling";
import { formatMoney } from "./helpers";

function calculateShipmentAndTotalPriceIfNoCoupon() {
  const coupon = JSON.parse(localStorage.getItem('coupon'));
  if (!coupon) {
    const ItemsPriceBeforeDiscount = Number($('#cart-items-price-unformatted').textContent);
    const discount = Number($('#discount-value-unformatted').textContent);
    const itemsPrice = ItemsPriceBeforeDiscount - discount;

    const shipmentPriceTag = $("#cart-shipment-price");
    const shipmentPriceUnformattedTag = $("#cart-shipment-price-unformatted");

    const shipmentPrices = JSON.parse($('#shipment-prices').textContent);
    const minFreeShipmentOrderValue = shipmentPrices.minFreeShipmentOrderValue;
    const cheapestShipment = shipmentPrices.cheapestShipment;

    const freeShipmentCounter = $("#free-shipment-counter");

    const totalPrice = $('#total-price');

    if (itemsPrice >= minFreeShipmentOrderValue) {
      shipmentPriceTag.innerHTML = "GRATIS";
      shipmentPriceUnformattedTag.innerHTML = 0;

      freeShipmentCounter.classList.add('hidden');

      totalPrice.innerHTML = formatMoney(itemsPrice + 0);
    }
    else {
      shipmentPriceTag.innerHTML = "od " + formatMoney(cheapestShipment);
      shipmentPriceUnformattedTag.innerHTML = cheapestShipment;

      freeShipmentCounter.classList.remove('hidden');
      freeShipmentCounter.innerHTML = `Do darmowej dostawy brakuje: ${formatMoney(minFreeShipmentOrderValue - itemsPrice)}`;

      totalPrice.innerHTML = formatMoney(itemsPrice + cheapestShipment)
    }
  }
}

module.exports = { calculateShipmentAndTotalPriceIfNoCoupon };
