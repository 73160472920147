function handleCookieBannerClick(e) {
  // 1. save decision in localStorage and run FB code if permission is granted
  if (e.target.classList.contains("accept")) {
    localStorage.setItem("cookie-consent", "accept");
    // fbq("consent", "grant");
  } else {
    localStorage.setItem("cookie-consent", "deny");
  }
  // 2. hide banner
  const cookieBannerTag = document.querySelector(".cookie-banner");
  cookieBannerTag.style = "display: none";
}

function cookieBanner() {
  const cookieBannerTag = document.querySelector(".cookie-banner");
  let cookieConsent = localStorage.getItem("cookie-consent");
  if (!cookieConsent) {
    cookieBannerTag.style = "display: flex";

    const cookieButtons = cookieBannerTag.querySelectorAll("button");
    cookieButtons.forEach((button) =>
      button.addEventListener("click", handleCookieBannerClick)
    );
  } else {
    cookieBannerTag.style = "display: none";
  }
}

module.exports = { cookieBanner };
