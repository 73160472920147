function getDragAfterElement(container, y) {
  const draggableElement = [
    ...container.querySelectorAll(".draggable:not(.dragging)"),
  ];

  return draggableElement.reduce(
    (closest, child) => {
      const box = child.getBoundingClientRect();
      const offset = y - box.top - box.height / 2;
      if (offset < 0 && offset > closest.offset) {
        return { offset, element: child };
      } else {
        return closest;
      }
    },
    { offset: Number.NEGATIVE_INFINITY }
  ).element;
}

module.exports = { getDragAfterElement };
