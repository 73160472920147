import { $, $$ } from "./bling";

function singleItemAvailabilityOnPageLoad() {
  const currentItem = JSON.parse($("#item-data").textContent);
  if (currentItem.isSet) return;
  const cart = JSON.parse(localStorage.getItem("cart"));
  const availabilityTag = $("#item-availability");
  let availability = Number(availabilityTag.textContent);
  const availabilityMessageTag = $("#availability-message");

  if (cart) {
    cart.forEach((cartItem) => {
      if (cartItem._id === currentItem._id) {
        availability = Number(availabilityTag.textContent);
        availability = availability - cartItem.quantity;
        availabilityTag.innerHTML = availability;
      } else if (cartItem.pickedSetItems) {
        cartItem.pickedSetItems.forEach((cartSetItem) => {
          if (currentItem._id === cartSetItem._id) {
            availability = Number(availabilityTag.textContent);
            availability = availability - 1;
            availabilityTag.innerHTML = availability;
          }
        });
      }
    });
  }

  if (availability > 0 && availability < 3) {
    availabilityMessageTag.innerHTML = "Dostępność produktu na wyczerpaniu";
    availabilityMessageTag.classList.add("orange");
  }
  if (availability >= 3) {
    availabilityMessageTag.innerHTML = "Produkt jest dostępny";
    availabilityMessageTag.classList.add("green");
  }
  if (availability <= 0) {
    availabilityMessageTag.innerHTML =
      "Brak produktu, zapytaj o dostępność mailowo";
    availabilityMessageTag.classList.add("red");

    const addToCartButton = $(".add-to-cart");
    addToCartButton.disabled = true;
    addToCartButton.innerText = "Produkt niedostępny";
  }
}

function updateSetAvailabilityOnPageLoad() {
  const currentItem = JSON.parse($("#item-data").textContent);
  // if item is single item get out of function
  if (!currentItem.isSet) return;

  const cart = JSON.parse(localStorage.getItem("cart"));
  const setAvailabilityBeforeCartCheck = $(
    "#set-items-avail-before-cart-check"
  );
  const setAvailabilityAfterCartCheck = $("#set-items-avail-after-cart-check");

  let availability = JSON.parse(setAvailabilityBeforeCartCheck.textContent);

  if (cart) {
    cart.forEach((cartItem) => {
      if (cartItem._id === currentItem._id) {
        cartItem.pickedSetItems.forEach((cartSetItem) => {
          availability.forEach((item) => {
            if (cartSetItem._id === item._id) {
              item.available = item.available - cartItem.quantity;
            }
          });
          setAvailabilityAfterCartCheck.innerHTML = JSON.stringify(
            availability
          );
        });
      } else {
        currentItem.setItems.forEach((item) => {
          if (cartItem._id === item) {
            availability.forEach((i) => {
              if (i._id === item) {
                i.available = i.available - cartItem.quantity;
              }
            });
            setAvailabilityAfterCartCheck.innerHTML = JSON.stringify(
              availability
            );
          }
        });
      }
    });
  }

  checkSetAvailability();
}

function checkSetAvailability() {
  // get availability of all set items
  const availabilityTag = $("#set-items-avail-after-cart-check");
  let availability = JSON.parse(availabilityTag.textContent);

  // get avaiability of currently checked set items
  const checkedItems = $$(".colorSelect .current");
  const checkedItemsAvailabilityArray = [];
  checkedItems.forEach((curTag) => {
    let curItem = JSON.parse(curTag.parentNode.querySelector("input").value);
    availability.forEach((avail) => {
      if (avail._id === curItem._id) {
        checkedItemsAvailabilityArray.push({
          available: avail.available,
          _id: avail._id,
        });
      }
    });
  });

  // count same set items
  let itemsCounted = [];
  checkedItemsAvailabilityArray.forEach((item) => {
    if (itemsCounted.some((i) => i._id === item._id)) {
      itemsCounted.forEach((i) => {
        if (i._id === item._id) {
          i.available = i.available - 1;
        }
      });
    } else itemsCounted.push(item);
  });
  const availabilityArray = itemsCounted.map((item) => item.available);

  const leastAvailable = Math.min(...availabilityArray);
  const availabilityMessageTag = $("#availability-message");
  const addToCartButton = $(".add-to-cart");

  if (leastAvailable > 0 && leastAvailable < 3) {
    availabilityMessageTag.innerHTML =
      "Dostępność zestawu w tych kolorach jest na wyczerpaniu";
    availabilityMessageTag.classList.remove("red");
    availabilityMessageTag.classList.remove("green");
    availabilityMessageTag.classList.add("orange");

    addToCartButton.disabled = false;
    addToCartButton.innerText = "Dodaj do koszyka";
  }
  if (leastAvailable >= 3) {
    availabilityMessageTag.innerHTML = "Produkt jest dostępny";
    availabilityMessageTag.classList.remove("orange");
    availabilityMessageTag.classList.remove("red");
    availabilityMessageTag.classList.add("green");

    addToCartButton.disabled = false;
    addToCartButton.innerText = "Dodaj do koszyka";
  }
  if (leastAvailable <= 0) {
    availabilityMessageTag.innerHTML =
      "Brak zestawu, sprawdź inne kolory lub zapytaj o dostępność mailowo";
    availabilityMessageTag.classList.remove("green");
    availabilityMessageTag.classList.remove("orange");
    availabilityMessageTag.classList.add("red");

    addToCartButton.disabled = true;
    // setTimeout(() => (addToCartButton.style.opacity = 0.7), 400);
    addToCartButton.innerText = "Produkt niedostępny";
  }
}

module.exports = {
  singleItemAvailabilityOnPageLoad,
  updateSetAvailabilityOnPageLoad,
  checkSetAvailability,
};
