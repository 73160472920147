import { $, $$, on } from "./bling";
import { updateCounter } from "./updateCounter";

function addItemToCart() {
  const addButton = $('.big-yellow-button');
  addButton.style.opacity = 0.7;
  setTimeout(() => addButton.style.opacity = 1, 300);

  let availabilityTag = $('#item-availability');
  let availability = Number(availabilityTag.textContent);

  const item = JSON.parse($("#item-data").textContent);
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  let foundItem = cart.find((oldItem) => oldItem._id == item._id);

  const quantity = $('.quantity__input') ? parseInt($('.quantity__input').value) : 1;
  if (foundItem && availability >= quantity) {
    foundItem.quantity = foundItem.quantity + quantity;
    availabilityTag.innerHTML = availability - quantity;
  }
  else if (!foundItem && availability >= quantity) {
    const newCartItem = {
      _id: item._id,
      quantity: quantity,
      price: $("#item-price-unformatted").textContent,
      image: item.images[0],
      name: item.name,
      color: item.color,
      shipWithInpost: item.shipWithInpost,
      isItemSet: false,
      pickedSetItems: [],
    };
    cart.push(newCartItem);
    availabilityTag.innerHTML = availability - quantity;
  }
  else {
    const addToCartButton = $('.add-to-cart')
    addToCartButton.disabled = true;
    setTimeout(() => addButton.style.opacity = 0.7, 400);
    addToCartButton.innerHTML = "Produkt niedostępny"
    $('#stock-depleted').classList.remove('hidden');
    $('#availability-message').classList.add('hidden');
  }
  localStorage.setItem("cart", JSON.stringify(cart));
  // ** update item counters **
  const counterTags = $$(".item-counter");
  if (counterTags) counterTags.forEach((tag) => updateCounter(tag));
}

module.exports = { addItemToCart };
