import { formatMoney } from "./helpers";
import { $ } from "./bling";

function calculateBonusPriceForItem(productPicker) {
  const pickerSquares = productPicker.querySelectorAll("span");
  const pickedPicker = pickerSquares.find((square) =>
    square.classList.contains("current")
  );
  const itemData = JSON.parse(
    pickedPicker.parentNode.querySelector("input").value
  );
  return itemData.addPrice;
}

function calculateBonusPrice() {
  const productPickers = document.querySelectorAll(".product-picker");
  const bonusPrice = productPickers.reduce(
    (price, productPicker) => price + calculateBonusPriceForItem(productPicker),
    0
  );
  return bonusPrice;
}

function getBasePrice() {
  return Number(document.querySelector(".item-base-price").textContent);
}

function updatePrice() {
  // data
  const basePrice = getBasePrice();
  const bonusPrice = calculateBonusPrice();
  const oldPrice = document.querySelector(".item-old-price");
  // display tags
  const priceTag = document.querySelector("#item-price");
  const oldPriceTag = document.querySelector(".old-price");
  // data tags
  const priceHiddenTag = document.querySelector("#item-price-unformatted");
  const oldPriceHiddenTag = document.querySelector(
    "#item-oldPrice-unformatted"
  );

  if (oldPrice && oldPriceTag) {
    oldPriceTag.innerHTML = formatMoney(
      Number(oldPrice.textContent) + bonusPrice
    );
    oldPriceHiddenTag.innerHTML = Number(oldPrice.textContent) + bonusPrice;
  }

  priceTag.innerHTML = " " + formatMoney(basePrice + bonusPrice);
  priceHiddenTag.innerHTML = basePrice + bonusPrice;
}

module.exports = { updatePrice };
